.container {
  margin: 0px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 13px;
}
.loadingDots {
  position: absolute;
  bottom: 0px;
  left: 0px !important;
  z-index: 999;
  left: 50%;
  right: 50%;
  transform: translate(50%);
}
.loadingDotsInCircleProgress {
  margin: 0px !important;
}
.loadingDotsInCircleProgress div {
  background-color: var(--c_main_2_dark) !important;
  width: 10px !important;
  height: 10px !important;
  margin: 5px !important;
}
.counterContainer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
}
.loadingMessage {
  display: flex;
  justify-content: center;
  margin: 0 5px;
  margin-top: 30px;
}
.loadingMessage p {
  background-color: rgba(202, 197, 197, 0.3);
  color: aliceblue;
  margin: 0 auto;
  padding: 5px 10px;
  text-align: center;
  font-size: 24px;
  font-family: Roboto, Arial;
  line-height: 35px;
  border-radius: 0.8rem;
}
