.container {
  border: 2px solid white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  margin: 3px;
  font-size: 20px;
}
.title {
  padding-bottom: 2px;
}
.icon {
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.4);
}
.space {
  margin-left: 8px;
}
.selected {
  color: var(--c_main_2_dark);
}
