.itemContainer {
  position: relative;
  border: 2px solid black;
  border-radius: 15px;
  overflow: hidden;
  max-width: min-content;
  min-width: 295px;
  min-height: 415px;
  background-color: var(--c_main_2_darkest);
  margin: 0px 30px;
  margin-bottom: 30px;
  cursor: pointer;
  box-shadow: 0px 3px 6px 2px rgb(0 0 0 / 30%);
  text-decoration: none;
  transition: 0.4s;
}
.itemContainer:hover {
  box-shadow: 0px 3px 10px 6px rgb(0 0 0 / 70%);
  transform: scale(1.02);
}
.imageContainer {
  position: relative;
  height: 371px;
  display: flex;
  align-items: center;
}
.itemContainer img {
  width: 295px;
  max-width: 295px;
  height: auto;
  max-height: 371px;
  display: flex;
}
.loadingImage {
  position: absolute;
  top: 0;
  width: 295px;
  height: 370px;
  max-height: 371px;
  display: flex;
  background-color: var(--c_main_2_darkest_2);
}
.loadingImage::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.05) 20%,
    rgba(255, 255, 255, 0.07) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 1.5s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.title {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  opacity: 0.9;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  padding: 2px 7px;
  height: 35px;
  border-top: 2px solid black;
}
.amount {
  position: absolute;
  z-index: 1;
  bottom: 5px;
  right: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amount p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  font-family: "Energy", Arial, sans-serif;
  filter: drop-shadow(0px 0px 4px black);
}
.color {
  margin-right: 3px;
  font-size: 17px;
  opacity: 0.7;
  filter: drop-shadow(0px 0px 4px black);
}
.icon {
  background-color: var(--c_main_2_darkest_2);
  color: rgba(255, 255, 255, 0.8);
  top: 5px;
  font-size: 30px;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2));
}
.editIcon {
  position: absolute;
  left: 5px;
}
.deleteIcon {
  position: absolute;
  right: 5px;
}
/* SKELETON */
.itemContainerSkeleton {
  border: none;
  background-color: var(--c_main_2_darkest);
  cursor: default;
  box-shadow: none;
  animation: skeleton-loading-2 1s linear infinite alternate;
}
.itemContainerSkeleton:hover {
  box-shadow: none;
  transform: none;
}
.amountSkeleton {
  background-color: rgba(255, 255, 255, 0.09);
  min-width: 80px !important;
  min-height: 25px !important;
  border-radius: 5px;
  animation: skeleton-loading 1s linear infinite alternate;
}
.countrySkeleton {
  position: absolute;
  bottom: 5px;
  left: 5px;
  min-width: 38px !important;
}
.ptitleSkeleton {
  min-height: 50px;
  min-width: 50px;
  border: 1px solid red;
}
.titleSkeleton {
  background-color: rgba(255, 255, 255, 0.09);
  border-top: none;
  height: 45px;
}
.titleSkeleton p {
  background-color: rgba(255, 255, 255, 0.1);
  height: 10px;
  width: 65%;
  animation: skeleton-loading 1s linear infinite alternate;
}
.iconSkeleton {
  background-color: rgba(255, 255, 255, 0.1);
  min-height: 25px;
  min-width: 25px;
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: rgba(255, 255, 255, 0.05);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
@keyframes skeleton-loading-2 {
  0% {
    background-color: var(--c_main_2_darkest_light);
  }
  100% {
    background-color: var(--c_main_2_darkest);
  }
}
