.searchMessageContainer {
  color: aliceblue;
}
.searchMessage {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.icon {
  font-size: 200px;
}
.sadIcon {
  font-size: 28px;
  color: var(--c_main);
}
.searchMessageContainer p {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: rgba(202, 197, 197, 0.3);
  color: aliceblue;
  width: fit-content;
  margin: 0 auto;
  padding: 5px 20px;
  text-align: center;
  font-size: 24px;
  font-family: Roboto, Arial;
  line-height: 35px;
  border-radius: 0.8rem;
}
.searchCounterConteiner {
  background-color: rgba(255, 255, 255, 0.2);
  color: aliceblue;
  text-align: center;
  font-size: 20px;
  font-family: Roboto, Arial;
  max-width: fit-content;
  padding: 2px 20px;
  padding-bottom: 5px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 4px;
}
.flex {
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  max-width: fit-content;
  padding: 2px 10px;
  margin: 0 auto;
  border-radius: 4px;
}
.flex p {
  font-size: 17px;
  font-style: italic;
  font-weight: 400;
}
.procent {
  position: absolute;
  bottom: 14px;
  margin-left: 0px;
  color: var(--c_main_2_dark);
  font-family: Roboto, Arial;
  font-size: 12px;
}
.color {
  border-radius: 50%;
  filter: drop-shadow(0px 0px 1px black);
}
