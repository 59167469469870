.form label {
  color: white;
  font-size: 22px;
  text-align: left;
  padding-left: 5px;
}
.colorContainer {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 10px 0;
  margin-bottom: 20px;
  color: white;
}
.colorContainer:last-child {
  margin-bottom: 0px;
}
.colorsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-height: 130px;
  overflow-y: auto;
}
.colorsContainer::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);
}
.colorsContainer::-webkit-scrollbar-thumb {
  background-color: var(--c_main_2_light);
}
.choseContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 10px;
  padding-top: 10px;
}
.choseContainer input[type="color"] {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 60px;
  height: 40px;
}
.choseContainer input[type="text"] {
  width: 40%;
  height: auto;
  font-size: 18px !important;
  padding: 5px;
}
.addButton {
  font-size: 18px;
  padding: 5px 10px;
}
