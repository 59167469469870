.input {
  background-color: var(--c_main_2_light);
  height: 50px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  padding: 0 10px;
  font-weight: 500;
}
.input:focus {
  outline: 1px solid black;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.6);
}
.input::placeholder {
  color: black;
  opacity: 0.55;
}
.inputLabel {
  color: var(--c_error_red);
  margin-bottom: 20px;
  text-align: left;
  padding: 0 4px;
}
.inputLabel:last-child {
  margin-bottom: 0px;
}
.inputError {
  outline: 1px solid var(--c_error_red) !important;
  background-color: rgba(var(--c_error_red_rgb), 0.3) !important;
}
input[type="number"]::-webkit-inner-spin-button {
  opacity: 0;
}
