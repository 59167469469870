.form {
  z-index: 100;
  background-color: var(--c_main_2_darkest);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 97%;
  max-height: 85%;
  overflow-y: auto;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);
  padding: 50px 2px;
}
.formContainer {
  padding: 5px 0px;
  max-height: 60vh;
  overflow-y: auto;
}
.formContainer::-webkit-scrollbar {
  width: 4px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);
}
.formContainer::-webkit-scrollbar-thumb {
  background-color: var(--c_main_2_light);
}
.form h1 {
  color: aliceblue;
  font-family: Energy, Arial, Helvetica, sans-serif;
  font-size: 35px;
  margin-bottom: 35px;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  margin: 0 25px;
}
.closeIcon {
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
}
.buttonContainer {
  max-width: 87%;
  margin: 0 auto;
  margin-top: 20px;
}
.button {
  width: 100%;
}
@media (max-width: 900px) {
  .formContainer {
    max-height: 35vh;
  }
}
