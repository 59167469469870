.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.imgContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.backgroundImage {
  position: absolute;
  height: 80%;
  opacity: 0.05;
}
