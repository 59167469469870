.inputError {
  outline: 1px solid var(--c_error_red) !important;
  background-color: rgba(var(--c_error_red_rgb), 0.3) !important;
}
.inputError2 {
  border: 1px solid var(--c_error_red) !important;
  background-color: rgba(var(--c_error_red_rgb), 0.3) !important;
}
.selectContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 0;
  max-height: 130px;
  overflow-y: auto;
}
.selectContainer::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);
}
.selectContainer::-webkit-scrollbar-thumb {
  background-color: var(--c_main_2_light);
}
.form label {
  color: var(--c_error_red);
  margin-bottom: 20px;
  text-align: left;
  padding: 0 4px;
}
.form label:last-child {
  margin-bottom: 0px;
}
.uploadImage {
  background-color: var(--c_main_2_light);
  color: black !important;
  text-align: center !important;
  font-size: 17px;
  font-weight: 700;
  text-decoration: underline;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 10px !important;
  cursor: pointer;
  margin-bottom: 0px !important;
}
.loading {
  margin: 0px;
}
