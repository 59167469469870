.nav {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--c_main_2_darkest_2);
  color: white;
  width: 20%;
  height: 100vh;
  box-shadow: 1px 2px 6px 2px rgb(0 0 0 / 40%);
  padding: 20px 10px;
  animation: leftToRight 1s;
  transition: all 1s;
  overflow-x: hidden;
}
.closing {
  animation: rightToLeft 1s forwards;
}
@keyframes leftToRight {
  from {
    width: 0%;
  }
  to {
    width: 20%;
  }
}
@keyframes rightToLeft {
  from {
    width: 20%;
  }
  to {
    width: 0%;
  }
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 20px;
  text-transform: uppercase;
}
.nav ul {
  list-style-type: none;
  padding: 10px;
  font-size: 18px;
}
.nav li,
.li {
  border: 1px solid var(--c_main_dark);
  background-color: var(--c_main_2_darkest);
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  margin: 5px;
  padding: 2px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
}
.icon {
  margin-right: 5px;
  color: var(--c_main_dark);
}
.menuIcon {
  margin-right: 5px;
}
.closeIcon {
  position: absolute;
  top: 7px;
  right: 7px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 24px;
  cursor: pointer;
}
.searchContainer {
  margin: 5px;
  position: relative;
  border: 3px solid var(--c_main_dark);
  /*height: 200px;*/
  margin-top: 20px;
  padding: 12px 10px;
}
.searchContainer span {
  position: absolute;
  background-color: var(--c_main_2_darkest_2);
  top: -15px;
  left: 15px;
  padding: 0 10px;
}
.search {
  background-color: var(--c_main_2_darkest);
  color: aliceblue;
  display: flex;
  border: 1px solid var(--c_main_dark);
  align-items: center;
  height: 35px;
  white-space: nowrap;
}
.searchIcon {
  color: var(--c_main_dark);
  font-size: 30px;
  margin-left: 2px;
  min-height: max-content;
}
.search input {
  background-color: transparent;
  color: aliceblue;
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  padding: 5px;
}
.sectionTitle {
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 3px;
  white-space: nowrap;
}
.selectContainer {
  padding: 3px;
  border: 1px solid var(--c_main_dark);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  white-space: nowrap;
  height: 140px;
  max-height: 140px;
  overflow-y: auto;
}
.selectContainer::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);
}
.selectContainer::-webkit-scrollbar-thumb {
  background-color: var(--c_main_2_light);
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
@media (max-width: 1500px) {
  .nav {
    width: 30%;
  }
  @keyframes leftToRight {
    from {
      width: 0%;
    }
    to {
      width: 30%;
    }
  }
  @keyframes rightToLeft {
    from {
      width: 30%;
    }
    to {
      width: 0%;
    }
  }
}
@media (max-width: 1130px) {
  .nav {
    width: 40%;
  }
  @keyframes leftToRight {
    from {
      width: 0%;
    }
    to {
      width: 40%;
    }
  }
  @keyframes rightToLeft {
    from {
      width: 40%;
    }
    to {
      width: 0%;
    }
  }
}
@media (max-width: 815px) {
  .nav {
    width: 50%;
  }
  @keyframes leftToRight {
    from {
      width: 0%;
    }
    to {
      width: 50%;
    }
  }
  @keyframes rightToLeft {
    from {
      width: 50%;
    }
    to {
      width: 0%;
    }
  }
}
@media (max-width: 560px) {
  .nav {
    width: 100%;
  }
  @keyframes leftToRight {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
  @keyframes rightToLeft {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }
}
