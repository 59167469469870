.container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: aliceblue;
  align-items: center;
  justify-content: center;
}
.containerText {
  text-align: center;
}
.icon {
  color: var(--c_error_red);
  font-size: 130px;
}
.container h1 {
  color: var(--c_main_2_dark);
  font-size: 100px;
  font-weight: 800;
  line-height: 100px;
}
.container h2 {
  font-size: 28px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
}
.container h2 strong {
  font-size: 35px;
}
.containerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--c_main_2_dark);
  border-radius: 1rem;
  max-width: max-content;
  margin: 0 auto;
  margin-top: 10px;
  padding: 3px 15px;
  cursor: pointer;
  gap: 8px;
  text-decoration: none;
  color: aliceblue;
}
.containerButton p {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
}
.iconBack {
  font-size: 45px;
}
