.container {
  z-index: 99;
  position: sticky;
  top: 0;
  background-color: var(--c_main);
  background-image: linear-gradient(
    -90deg,
    var(--c_main),
    var(--c_main_2_dark),
    var(--c_main)
  );
  color: white;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0px 3px 6px 3px rgb(0 0 0 / 50%);
  padding: 2px;
}
.logo img {
  width: 150px;
  height: auto;
  padding: 0px;
  margin: 0;
}
.menu {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--c_main_2_darkest_1);
  cursor: pointer;
  width: 32px;
  min-height: 100%;
  height: 35px;
  margin-left: 15px;
}
