@import url("./styles/colors.css");

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--c_background);
}

@font-face {
  font-family: "DS-DIGI";
  src: local("DS-DIGI"), url(./fonts/DS-DIGI.ttf) format("truetype");
}

@font-face {
  font-family: "Energy";
  src: local("Energy"), url(./fonts/Energy.ttf) format("truetype");
}

@font-face {
  font-family: "StormGust";
  src: local("StormGust"), url(./fonts/StormGust.ttf) format("truetype");
}

@font-face {
  font-family: "BACKCOUNTRY";
  src: local("BACKCOUNTRY"),
    url(./fonts/BACKCOUNTRY-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "BabalusaCut";
  src: local("BabalusaCut"),
    url(./fonts/BACKCOUNTRY-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "DoctorGlitch";
  src: local("DoctorGlitch"), url(./fonts/DoctorGlitch.otf) format("truetype");
}

@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);

/*::-webkit-scrollbar {
  width: 15px;
  height: 30px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, var(--c_cyan), var(--c_cyan_dark));
  border-radius: 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0 3px 13px 1px;
}*/
