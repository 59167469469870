.imgContainer {
  display: flex;
  justify-content: center;
}
.imgContainer img {
  width: 400px;
  height: auto;
}
.errorText {
  color: whitesmoke;
  border: 1px solid whitesmoke;
  text-align: center;
  font-size: 38px;
  font-family: "Energy", Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  max-width: 400px;
  width: 400px;
  margin: 0 auto;
  padding: 5px;
}
@media (max-width: 450px) {
  .container img {
    width: 80%;
  }
  .errorText {
    max-width: 80%;
    font-size: 35px;
  }
}
