.container {
  min-width: 100%;
  min-height: 80vh;
  padding: 20px 0;
}
.itemContainer {
  border: 3px solid black;
  background-color: var(--c_main_2_darkest);
  color: white;
  display: block;
  box-sizing: unset;
  max-width: 450px;
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 3px 10px 4px rgb(0 0 0 / 30%);
}
.imgContainer {
  position: relative !important;
  display: flex;
}
.imgContainer img {
  display: flex;
  width: 450px;
  max-height: auto; /**/
}
.title {
  text-align: center;
  font-size: 23px;
  font-family: "Energy", Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  opacity: 0.9;
  padding: 10px 8px;
  padding-bottom: 15px;
  border-top: 3px solid black;
}
.amount {
  position: absolute;
  bottom: 1px;
  right: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 38px;
  font-family: "Energy", Arial, sans-serif;
  filter: drop-shadow(0px 0px 4px black);
}
.flag {
  display: flex;
  position: absolute;
  bottom: 5px;
  left: 8px;
  width: 50px;
  height: auto;
  opacity: 0.6;
  border: 2px solid black;
  border-radius: 7px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 1.2));
}
.loadingImage {
  position: absolute;
  top: 0;
  width: 1000px;
  min-height: 600px;
  display: flex;
  background-color: var(--c_main_2_darkest_2);
}
.loadingImage::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.05) 20%,
    rgba(255, 255, 255, 0.07) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 1.5s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@media (max-width: 520px) {
  .itemContainer {
    max-width: 92%;
  }
  .imgContainer {
    min-height: 500px;
  }
  .imgContainer img {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
  }
}
